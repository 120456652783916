.topbar {
    background: rgb(158,157,157);
    background: linear-gradient(180deg, rgba(237, 237, 237, 0.754) 0%, rgb(241, 241, 241) 50%, rgb(238, 238, 238) 100%);
    box-shadow:1px 1px 2px #aaa;
    padding:5px;
    height:56px;
    display:flex;
    margin-bottom:10px;
    z-index: 10;
    width: 100%;
    font-size:13px;
    position: fixed;
    top: 0px;
    align-items: center;
    justify-content: left;

  }
  
  .zoom-controls, .reset {
    display: flex;
    justify-content: left;
  }

  .topbar > .zoom-controls > svg, .topbar > .reset {
    width:24px;
    height:24px;
    margin:0px 5px 0px 5px;
    opacity:0.8;
  }
  
  .topbar > .zoom-controls > svg:hover, .topbar > .reset:hover {
    stroke-width:1px;
    stroke:#2684ff;
  }
  
  .topbar > .zoom-controls > svg.selected, .topbar > .reset {
    /*stroke-width:1px;*/
    fill:#2684ff;
    opacity:1;
  }
  .form__group,
  .form__group--inline {
    padding: none;
  }
  .zoom-selector {
    width: 110px;
  }
  .form__group--zoom {
    padding: 0;
  }
  .radio-inline input[type="radio"] {
    margin-right: 0.35rem;
  }
  .radio-inline,
  button,
  input[type="radio"] {
    cursor: pointer;
  }
  .form__group input[type="number"],
  .form__group input[type="text"] {
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    color: #495057;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    font-weight:bold;
    padding: 8px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: 60px;
  }
  .form__group input[type="number"]:focus,
  .form__group input[type="text"]:focus {
    background-color: #fff;
    border-color: #2684ff;
    box-shadow: 0 0 0 1px #2684ff;
    color: #495057;
    outline: 0;
  }
  .form__group .input-inline {
    display: inline-block;
  }
  .form__group .input-inline input[type="number"],
  .form__group .input-inline input[type="text"] {
    margin: 0 0.5rem;
  }
  .form__group .form__group--inline .button {
    width: auto;
    height:34px;
    font-size:13px;
    margin-left:10px;
  }
  .form__group--inline {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
    padding-left:10px;
  }
  .form__title {
    display: block;
  }

  div[class$="-placeholder"] {
    color: #000;
    font-weight:bold;
    font-size:14px;
    width:240px;
  }
  .index-selector.index {
    border:2px solid #2684ff;
    border-radius:4px;
    font-size:13px;
    font-weight:bold;
    color:#000;
    width: 280px;
  }
  .index-selector.zoom{
    width:94px;
    margin-right:5px;
  }

  .index-selector.toggle {
    margin-left: 5px;
    min-width:90px;
  }

  .divider {
    padding:0px 0px 0px 15px;
    margin-left:15px;
    border-left:1px #c0c0c0 solid;
    height:55px;
  }

  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

label, label.form__title {
    display:grid;
}

@media only screen and (max-width: 1468px) {
   .topbar {
    justify-content: left;
   }
   .topbar > *, .label-sm, .radio-inline {
    transform: scale(0.9);
   }
   .topbar > .logo{ display: none; }
   .divider { margin-left: 0px; padding: 0px 0px 0px 5px; display: block; }
   .inflation { min-width:100px }
   .zoom { min-width: 95px; padding-top:18px; }
   .year-range, .zoom-controls, .reset, .index-selector.index { margin-top: 16px; }
   .topbar > .reset { margin-top: 16px; }
  }

  @media only screen and (max-width: 1200px) {
    .zoom-controls { display: none }
    .sidebar-collapsed > div > .legend-wedge {
      display: none;
    }
    .mini-chart, .legend-logo {
      display: none;
    }
    .form__group input[type="number"] {
      width:50px;
      font-size: 12px;
    }
    .index-selector.index {
      min-width: 240px;
    }
  }

  @media only screen and (max-width: 800px) {
    #sidebar {
      display: none;
    }
  }
  
  /* For general iPad layouts */
  @media only screen and (device-width: 768px) {
    #main {
      height: calc(var(--domgrid-height) - 60px);
    }
    .data__chart {
      height: 275px !important;
    }
    .compare-table .ReactTable {
      height: 270px !important;
    }
  }
 