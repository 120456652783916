:root {
  --standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
  --main-width: 75vw;
  --sidebar--width: 255px;
  --domgrid-height: calc(100vh);
}

* {
  box-sizing: border-box;
}

html {
  font-family: "Nunito", sans-serif;
}

body {
  margin: 0;
  padding: 0;
  font-size: 14px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

/* Form elements don't inherit font settings, you have to set these properties manually. */
body,
input,
textarea,
button {
  font-family: "Nunito", sans-serif;
  /* font-family: sans-serif, serif; */
}

#main {
  overflow: hidden;
  position: absolute;
  margin-top:50px;
}

#sidebar {
  position: fixed;
  right: 0;
  bottom: 38px;
  width: var(--sidebar--width);
  padding: 0.2rem;
  background-color: white;
  text-align: center;

  transition: transform 0.5s var(--standard-curve);
  will-change: transform;
}

.ReactTable {
  border: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: relative;
}
.ReactTable * {
  box-sizing: border-box;
}
.ReactTable .rt-table {
  align-items: stretch;
  border-collapse: collapse;
  display: flex;
  flex: auto 1;
  flex-direction: column;
  overflow: auto;
  width: 100%;
}
.ReactTable .rt-thead {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
  -webkit-user-select: none;
  user-select: none;
}
.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable .rt-thead.-filters,
.ReactTable .rt-thead.-headerGroups {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: inherit;
  font-weight: 400;
  outline: none;
  padding: 5px 7px;
}
.ReactTable .rt-thead.-filters .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-thead.-header {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}
.ReactTable .rt-thead .rt-tr {
  text-align: center;
}
.ReactTable .rt-thead .rt-td,
.ReactTable .rt-thead .rt-th {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 0 0 0 transparent;
  line-height: normal;
  padding: 5px;
  position: relative;
  transition: box-shadow 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.ReactTable .rt-thead .rt-td.-sort-asc,
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: inset 0 3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-td.-sort-desc,
.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: inset 0 -3px 0 0 rgba(0, 0, 0, 0.6);
}
.ReactTable .rt-thead .rt-td.-cursor-pointer,
.ReactTable .rt-thead .rt-th.-cursor-pointer {
  cursor: pointer;
}
.ReactTable .rt-thead .rt-td:last-child,
.ReactTable .rt-thead .rt-th:last-child {
  border-right: 0;
}
.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}
.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden;
}
.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
}
.ReactTable .rt-thead .rt-header-pivot {
  border-right-color: #f7f7f7;
}
.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  border: solid transparent;
  content: " ";
  height: 0;
  left: 100%;
  pointer-events: none;
  position: absolute;
  top: 50%;
  width: 0;
}
.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0) hsla(0, 0%, 100%, 0)
    #fff;
  border-width: 8px;
  margin-top: -8px;
}
.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: hsla(0, 0%, 40%, 0) hsla(0, 0%, 40%, 0) hsla(0, 0%, 40%, 0)
    #f7f7f7;
  border-width: 10px;
  margin-top: -10px;
}
.ReactTable .rt-tbody {
  display: flex;
  flex: 99999 1 auto;
  flex-direction: column;
  overflow: auto;
}
.ReactTable .rt-tbody .rt-tr-group {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0;
}
.ReactTable .rt-tbody .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.02);
}
.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0;
}
.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer;
  text-overflow: clip;
}
.ReactTable .rt-tr-group {
  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.ReactTable .rt-tr {
  display: inline-flex;
  flex: 1 0 auto;
}
.ReactTable .rt-td,
.ReactTable .rt-th {
  flex: 1 0;
  overflow: hidden;
  padding: 7px 5px;
  text-overflow: ellipsis;
  transition: 0.3s ease;
  transition-property: width, min-width, padding, opacity;
  white-space: nowrap;
}
.ReactTable .rt-td.-hidden,
.ReactTable .rt-th.-hidden {
  border: 0 !important;
  min-width: 0 !important;
  opacity: 0 !important;
  padding: 0 !important;
  width: 0 !important;
}
.ReactTable .rt-expander {
  color: transparent;
  display: inline-block;
  margin: 0 10px;
  position: relative;
}
.ReactTable .rt-expander:after {
  border-left: 5.04px solid transparent;
  border-right: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  content: "";
  cursor: pointer;
  height: 0;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  width: 0;
}
.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0);
}
.ReactTable .rt-resizer {
  bottom: 0;
  cursor: col-resize;
  display: inline-block;
  position: absolute;
  right: -18px;
  top: 0;
  width: 36px;
  z-index: 10;
}
.ReactTable .rt-tfoot {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}
.ReactTable .rt-tfoot .rt-td {
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}
.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0;
}
.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03);
}
.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05);
}
.ReactTable .-pagination {
  align-items: stretch;
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 3px;
  z-index: 1;
}
.ReactTable .-pagination input,
.ReactTable .-pagination select {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 3px;
  font-size: inherit;
  font-weight: 400;
  outline: none;
  padding: 5px 7px;
}
.ReactTable .-pagination .-btn {
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 3px;
  color: rgba(0, 0, 0, 0.6);
  cursor: pointer;
  display: block;
  font-size: 1em;
  height: 100%;
  outline: none;
  padding: 6px;
  transition: all 0.1s ease;
  width: 100%;
}
.ReactTable .-pagination .-btn[disabled] {
  cursor: default;
  opacity: 0.5;
}
.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ReactTable .-pagination .-next,
.ReactTable .-pagination .-previous {
  flex: 1 1;
  text-align: center;
}
.ReactTable .-pagination .-center {
  align-items: center;
  display: flex;
  flex: 1.5 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-bottom: 0;
  text-align: center;
}
.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}
.ReactTable .-pagination .-pageJump {
  display: inline-block;
}
.ReactTable .-pagination .-pageJump input {
  text-align: center;
  width: 70px;
}
.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px;
}
.ReactTable .rt-noData {
  color: rgba(0, 0, 0, 0.5);
  left: 50%;
  padding: 20px;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
.ReactTable .-loading,
.ReactTable .rt-noData {
  background: hsla(0, 0%, 100%, 0.8);
  display: block;
  pointer-events: none;
  position: absolute;
  transition: all 0.3s ease;
}
.ReactTable .-loading {
  bottom: 0;
  left: 0;
  opacity: 0;
  right: 0;
  top: 0;
  z-index: -1;
}
.ReactTable .-loading > div {
  color: rgba(0, 0, 0, 0.6);
  display: block;
  font-size: 15px;
  left: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-52%);
  transform: translateY(-52%);
  transition: all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  width: 100%;
}
.ReactTable .-loading.-active {
  opacity: 1;
  pointer-events: all;
  z-index: 2;
}
.ReactTable .-loading.-active > div {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ReactTable .rt-resizing .rt-td,
.ReactTable .rt-resizing .rt-th {
  cursor: col-resize;
  transition: none !important;
  -webkit-user-select: none;
  user-select: none;
}
.data__chart,
.data__table {
  float: left;
  padding: 1%;
  width: 50%;
}
.data__note {
  font-size: 0.7rem;
  font-style: italic;
  padding-top: 1rem;
}
.data__chart {
  height: 335px;
}
.ReactTable .rt-thead {
  font-weight: 700;
  overflow-y: scroll;
}
.rc-drawer {
  position: fixed;
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: width 0s ease 0.3s, height 0s ease 0.3s,
    transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  z-index: 9999;
}
.rc-drawer > * {
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    box-shadow 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.rc-drawer.rc-drawer-open {
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.rc-drawer .rc-drawer-mask {
  background: #000;
  height: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    height 0s ease 0.3s;
  width: 100%;
}
.rc-drawer-content-wrapper {
  background: #fff;
  position: absolute;
}
.rc-drawer-content {
  overflow: auto;
  position: relative;
  z-index: 1;
}
.rc-drawer-handle {
  align-items: center;
  background: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  height: 40px;
  justify-content: center;
  line-height: 40px;
  position: absolute;
  text-align: center;
  top: 72px;
  width: 41px;
  z-index: 0;
}
.rc-drawer-handle-icon {
  background: #333;
  height: 2px;
  position: relative;
  transition: background 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  width: 14px;
}
.rc-drawer-handle-icon:after,
.rc-drawer-handle-icon:before {
  background: #333;
  content: "";
  display: block;
  height: 2px;
  position: absolute;
  transition: -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86),
    -webkit-transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
  width: 100%;
}
.rc-drawer-handle-icon:before {
  top: -5px;
}
.rc-drawer-handle-icon:after {
  top: 5px;
}
.rc-drawer-left,
.rc-drawer-right {
  height: 100%;
  width: 0;
}
.rc-drawer-left .rc-drawer-content,
.rc-drawer-left .rc-drawer-content-wrapper,
.rc-drawer-right .rc-drawer-content,
.rc-drawer-right .rc-drawer-content-wrapper {
  height: 100%;
}
.rc-drawer-left.rc-drawer-open,
.rc-drawer-right.rc-drawer-open {
  width: 100%;
}
.rc-drawer-left.rc-drawer-open.no-mask,
.rc-drawer-right.rc-drawer-open.no-mask {
  width: 0;
}
.rc-drawer-left {
  left: 0;
  top: 0;
}
.rc-drawer-left .rc-drawer-handle {
  border-radius: 0 4px 4px 0;
  right: -40px;
}
.rc-drawer-left .rc-drawer-handle,
.rc-drawer-left.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-right {
  right: 0;
  top: 0;
}
.rc-drawer-right .rc-drawer-content-wrapper {
  right: 0;
}
.rc-drawer-right .rc-drawer-handle {
  border-radius: 4px 0 0 4px;
  left: -40px;
}
.rc-drawer-right .rc-drawer-handle,
.rc-drawer-right.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-right.rc-drawer-open.no-mask {
  right: 1px;
  -webkit-transform: translateX(1px);
  transform: translateX(1px);
}
.rc-drawer-bottom,
.rc-drawer-top {
  height: 0;
  width: 100%;
}
.rc-drawer-bottom .rc-drawer-content,
.rc-drawer-bottom .rc-drawer-content-wrapper,
.rc-drawer-top .rc-drawer-content,
.rc-drawer-top .rc-drawer-content-wrapper {
  width: 100%;
}
.rc-drawer-bottom .rc-drawer-content,
.rc-drawer-bottom.rc-drawer-open,
.rc-drawer-top .rc-drawer-content,
.rc-drawer-top.rc-drawer-open {
  height: 100%;
}
.rc-drawer-bottom.rc-drawer-open.no-mask,
.rc-drawer-top.rc-drawer-open.no-mask {
  height: 0;
}
.rc-drawer-bottom .rc-drawer-handle,
.rc-drawer-top .rc-drawer-handle {
  left: 50%;
  margin-left: -20px;
}
.rc-drawer-top {
  left: 0;
  top: 0;
}
.rc-drawer-top .rc-drawer-handle {
  border-radius: 0 0 4px 4px;
  bottom: -40px;
  top: auto;
}
.rc-drawer-top .rc-drawer-handle,
.rc-drawer-top.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-bottom {
  bottom: 0;
  left: 0;
}
.rc-drawer-bottom .rc-drawer-content-wrapper {
  bottom: 0;
}
.rc-drawer-bottom .rc-drawer-handle {
  border-radius: 4px 4px 0 0;
  top: -40px;
}
.rc-drawer-bottom .rc-drawer-handle,
.rc-drawer-bottom.rc-drawer-open .rc-drawer-content-wrapper {
  box-shadow: 0 -2px 8px rgba(0, 0, 0, 0.15);
}
.rc-drawer-bottom.rc-drawer-open.no-mask {
  bottom: 1px;
  -webkit-transform: translateY(1px);
  transform: translateY(1px);
}
.rc-drawer.rc-drawer-open .rc-drawer-mask {
  height: 100%;
  opacity: 0.3;
  transition: opacity 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon {
  background: transparent;
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:before {
  -webkit-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg);
}
.rc-drawer.rc-drawer-open .rc-drawer-handle-icon:after {
  -webkit-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-5px) rotate(-45deg);
}
.react-tabs {
  -webkit-tap-highlight-color: transparent;
}
.react-tabs__tab-list {
  border-bottom: 1px solid #aaa;
  margin: 0 0 10px;
  padding: 0;
}
.react-tabs__tab {
  border: 1px solid transparent;
  border-bottom: none;
  bottom: -1px;
  cursor: pointer;
  display: inline-block;
  list-style: none;
  padding: 6px 12px;
  position: relative;
}
.react-tabs__tab--selected {
  background: #fff;
  border-color: #aaa;
  border-radius: 5px 5px 0 0;
  color: #000;
}
.react-tabs__tab--disabled {
  color: GrayText;
  cursor: default;
}
.react-tabs__tab:focus {
  outline: none;
}
.react-tabs__tab:focus:after {
  background: #fff;
  bottom: -5px;
  content: "";
  height: 5px;
  left: -4px;
  position: absolute;
  right: -4px;
}
.react-tabs__tab-panel {
  display: none;
}
.react-tabs__tab-panel--selected {
  display: block;
}
.rc-drawer__handle {
  background: #424242;
  border-top: 1px solid #000;
  bottom: 0;
  color: #fff;
  cursor: pointer;
  left: 0;
  padding: 0.25rem;
  text-align: center;
  width: 100%;
}
.rc-drawer__title {
  font-size: 1.4rem;
  margin-right: 0.5rem;
}
.rc-drawer__icon {
  width: 1rem;
}
.rc-drawer__icon g path {
  stroke: #ccc;
  fill: #ccc;
}
.react-tabs {
  margin: 0.5rem;
  text-align: center;
}
.matrix-selector__placeholder {
  color: #000 !important;
}
.rc-drawer-wrapper {
  bottom: 0;
  height: 50%;
  top: auto !important;
}
:root {
  --header-height: 78px;
  --logo-height: 32px;
}
.appSubtitle,
.appTitle {
  color: #39729b;
}
.appTitle {
  font-size: 2em;
}
.appTitle sup {
  font-size: 0.5em;
  padding-left: 0.2em;
}
.appSubtitle {
  font-size: 1.25em;
  font-weight: 700;
}
.controls {
  position: absolute;
  right: 1rem;
  top: 1rem;
}
.controls .rc-drawer__icon g path {
  stroke: #39729b;
  fill: #39729b;
}
.header-logo {
  padding: 0.25rem 0 0 !important;
}
.logo {
  max-height: 32px;
  max-height: var(--logo-height);
}
.index-selector {
  font-size: 0.8rem;
  text-align: left;
}
.header {
  cursor: pointer;
  position: relative;
}
.form__top .form__group {
  background-color: #ccdced;
}
.form__top .form__group .form__title {
  margin-bottom: 0.4rem;
}
.button {
  background: transparent;
  background-color: initial;
  background-image: none;
  border: 1px solid #666;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 400 !important;
  height: 100%;
  padding: 0.5rem 1.5rem;
  transition: background-color 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  transition: background-color 0.3s var(--standard-curve);
  width: 100%;
}
.button:hover {
  background-color: #b6d3c6;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
.button-sm {
  font-size: 0.7rem;
  padding: 0.4rem;
}
.button-primary {
  background-color: #39729b;
  border-color: #33668b;
  color: #fff;
}
.button-primary:hover {
  background-color: #1c394d !important;
  border-color: #33668b;
}
.button,
.form__title {
  font-weight: 700;
}

:root {
  --cell-height: 1rem;
  --cell-padding: 0.05rem;
  --drawer-height: 40px;
  --cell-heightpx: 1rem;
}
.domgrid {
  display: inline-flex;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  position: relative;
  z-index: 10;
}
.domgrid__cell {
  box-sizing: border-box;
  font-size: 1rem;
  font-size: var(--cell-height);
  padding: 0.05rem 0.1rem;
  padding: var(--cell-padding) calc(var(--cell-padding) + 0.05rem);
  text-align: center;
}
.domgrid__footer,
.domgrid__header {
  /* font-style: italic; */
}
.domgrid__y-axis {
  padding-right: 0.2rem;
}
.text--subdued {
  color: #797979;
}
@media only screen and (device-width: 768px) {
  .domgrid__cell {
    height: 1rem;
    height: var(--cell-heightpx);
  }
}
:root {
  --standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
  --main-width: 75vw;
  --sidebar--width: 255px;
  --domgrid-height: calc(100vh - 42px);
}
* {
  box-sizing: border-box;
}
html {
  font-family: Nunito, sans-serif;
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  margin: 0;
  padding: 0;
  overflow: auto;
}
body,
html {
  height: 100%;
  width: 100%;
}
body,
button,
input,
textarea {
  font-family: Nunito, sans-serif;
}
#main {
  width: calc(100vw + 1200px);
  overflow: hidden;
  -webkit-user-select: none;
  user-select: none;
}

#main.draggable {
  cursor: grab;
}
#sidebar {
  background-color: #fff;
  bottom: 38px;
  padding: 0.2rem;
  position: fixed;
  right: 0;
  text-align: center;
  transition: -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1),
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  transition: -webkit-transform 0.5s var(--standard-curve);
  transition: transform 0.5s var(--standard-curve);
  transition: transform 0.5s var(--standard-curve),
    -webkit-transform 0.5s var(--standard-curve);
  width: 355px;
  width: var(--sidebar--width);
  will-change: transform;
}

.back-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #1c394d;
  z-Index:1;
}

@media only screen and (device-width: 768px) {
  #main {
    height: calc(100vh - 102px);
    height: calc(var(--domgrid-height) - 100px);
  }
  .data__chart {
    height: 275px !important;
  }
  .compare-table .ReactTable {
    height: 270px !important;
  }
  #main {
    height: calc(var(--domgrid-height) - 60px);
  }
  .data__chart {
    height: 275px !important;
  }
  .compare-table .ReactTable {
    height: 270px !important;
  }
}


