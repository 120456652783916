:root {
  --standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
  --header-height: 78px;
  --logo-height: 32px;
}

.appTitle,
.appSubtitle {
  color: #39729b;
}

.appTitle {
  font-size: 2em;
}

.appTitle sup {
  font-size: 0.5em;
  padding-left: 0.2em;
}

.appSubtitle {
  font-size: 1.25em;
  font-weight: bold;
}

.controls {
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.controls .drawer__icon g path {
  stroke: #39729b;
  fill: #39729b;
}

.header-logo {
  padding: 0.25rem 0 0 0 !important;
}

.logo {
  max-height: var(--logo-height);
}

.index-selector {
  font-size: 0.8rem;
  text-align: left;
}

.header {
  position: relative;
  cursor: pointer;
}
.range-key {
  position: absolute;
  width: 190px;
  margin-left: 40px;
  bottom: 0%;
  margin-bottom: 25px;
  font-size: 12px;
  padding: 4px;
  background-color: #fff;
  display: block;
  text-align: center;
}

.range-key-row.header {
  border-bottom:2px solid #ccc;
}

.range-key-row.footer {
  border-top:2px solid #ccc;
}

.range-key-row > div:nth-child(1) {
  margin-right:5px;
  display: inline-block;
  width:20px;
}

.range-key-row > div:nth-child(2) {
  display: inline-block;
  width:95px;
}

.range-key-row > div:nth-child(3) {
  display: inline-block;
  width:60px;
}

.mini-chart {
  position: fixed;
  bottom: 0px;
  width: 425px;
  height: 200px;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  right: 0;
  margin-right: 220px;
  margin-bottom: 60px;
}

.recharts-legend-item-text {
  font-weight: bold;
  font-size:15px;
}

.legend-wedge {
  position:fixed;
  bottom: 0px;
  right: 40px;
  background-color: #ffffffe0;
  height: 440px;
  width: 650px;
  box-shadow: inset;
  clip-path: polygon(46% 0%, 100% 0, 100% 100%, 0 100%, 0% 38%);
  /*transform: rotate(-29deg);*/
  box-shadow: 1px 1px 50px #fff;
}

.legend-logo {
  position: fixed;
  bottom: 120px;
  right: 225px;
  width: 260px;
  height: 200px;
  z-Index: 10;
  text-align: right;
}

.legend-logo > .logo {
  height:30px;
}

.legend-logo > * > .appTitle {
  font-size: 18px;
  line-height: 8px;
}

.legend-logo > * > .appSubtitle {
  font-size: 14px;
}

.sidebar-collapsed {
  bottom:40px;
}