:root {
  --standard-curve: cubic-bezier(0.4, 0, 0.2, 1);
  --cell-height: 1rem;
  --cell-padding: 0.05rem;
  --drawer-height: 40px;
  --cell-heightpx: 1rem;
}

.domgrid {
  display: inline-flex;
  margin-top: 0.3rem;
  margin-left: 0.5rem;
}

.domgrid__cell {
  box-sizing: border-box;
  font-size: var(--cell-height);
  padding-top: var(--cell-padding);
  padding-right: calc(var(--cell-padding) + 0.05rem);
  padding-bottom: var(--cell-padding);
  padding-left: calc(var(--cell-padding) + 0.05rem);
  transition: background-color 0.1s; 
  text-align: center;
  /* doesnt work because component re-renders */
  /* transition: font-size 0.3s var(--standard-curve); */
}

.hovered {
  box-shadow: inset 0px 0px 0px 1px #000;
}

.domgrid__header,
.domgrid__footer {
  font-style: italic;
  cursor: pointer;
  border-bottom: 1px solid #c0c0c0;
}

.domgrid__y-axis {
  padding-right: 0.2rem;
}

.text--subdued {
  color: #797979;
}

.domgrid__cell-value {
  /* TODO: update colors */
}

/* For general iPad layouts */
@media only screen and (device-width: 768px) {
  .domgrid__cell {
    height: var(--cell-heightpx);
  }
}

@media only screen and (max-width: 1280px) {
    .domgrid__cell {
      transition: none;
    }
}