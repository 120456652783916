.drawer__handle {
  left: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid black;
  background: #424242;
  color: white;
  text-align: center;
  padding: 0.25rem;
  cursor: pointer;
}

.drawer__title {
  font-size: 1.4rem;
  margin-right: 0.5rem;
}

.drawer__icon {
  width: 1rem;
}

.drawer__icon g path {
  stroke: #ccc;
  fill: #ccc;
}

/* React Tabs */
.react-tabs {
  text-align: center;
  margin: 0.5rem;
}

/* ------------------------------------ */
/* unused */
.matrix-selector__placeholder {
  color: #000 !important;
}
/* unused */
.drawer-wrapper {
  top: initial !important;
  bottom: 0;
  height: 50%;
}
